<template>
  <validation-observer
    ref="memberSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="memberSaveModal"
      body-class="position-static"
      centered
      size="lg"
      title="Thêm thành viên"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="trainingSystemId"
                  label="name"
                  :options="trainingSystems"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  id="courseId"
                  v-model="courseId"
                  label="name"
                  :options="courses"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-for="studentIds">
              <template v-slot:label>
                Thành viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thành viên"
                rules="required"
              >
                <v-select
                  id="studentIds"
                  v-model="studentIds"
                  label="label"
                  multiple
                  :options="students"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('memberSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'

export default {
  name: 'ResearchMemberSave',
  components: {
    BCol,
    BRow,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    researchStudentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      trainingSystemId: null,
      courseId: null,
      studentIds: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      students: 'researchStudentMember/allStudents',
      trainingSystems: 'researchStudentMember/trainingSystems',
      courses: 'researchStudentMember/courses',
    }),
  },
  watch: {
    async trainingSystemId(val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.courseId = this.courses[0].id
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.courseId = null
        this.studentIds = []
        this.setCourses([])
        this.setStudents([])
      }
    },
    async courseId(val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getStudentsByCourse({ courseId: val, currentPage: 1, itemsPerPage: 100000 })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.studentIds = []
        this.setStudents([])
      }
    },
  },
  methods: {
    ...mapMutations({
      setCourses: 'researchStudentMember/SET_COURSES',
      setStudents: 'researchStudentMember/SET_ALL_STUDENTS',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'researchStudentMember/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'researchStudentMember/getCoursesByTrainingSystemId',
      getStudentsByCourse: 'researchStudentMember/getStudentsByCourse',
      createMember: 'researchStudentMember/createResearchStudentMember',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
        if (this.trainingSystems.length > 0) {
          this.trainingSystemId = this.trainingSystems[0].id
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .memberSaveFormRef
        .reset()
      this.trainingSystemId = null
      this.courseId = null
      this.studentIds = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .memberSaveFormRef
        .validate()
      if (valid) {
        const body = []
        this.studentIds.forEach(studentId => {
          body.push({ studentId, researchStudentId: this.researchStudentId, status: Flag.ACTIVE })
        })
        this.isLoading = true
        try {
          const response = await this.createMember(body)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('memberSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
