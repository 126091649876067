<template>
  <validation-observer
    ref="researchSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="researchSaveModal"
      body-class="position-static"
      centered
      size="lg"
      :title="isCreated ? 'Thêm đề tài nghiên cứu' : 'Cập nhật đề tài nghiên cứu'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên đề tài"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetResearch.name"
                  name="name"
                  placeholder="Nhập tên đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã đề tài"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetResearch.code"
                  name="code"
                  placeholder="Nhập mã đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Ngày bắt đầu"
              label-for="startDate"
            >
              <b-input-group class="input-group">
                <flat-pickr
                  id="startDate"
                  v-model="targetResearch.startDate"
                  class="form-control"
                  :config="config"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    @click="targetResearch.startDate = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Ngày kết thúc"
              label-for="endDate"
            >
              <b-input-group class="input-group">
                <flat-pickr
                  id="endDate"
                  v-model="targetResearch.endDate"
                  class="form-control"
                  :config="config"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    @click="targetResearch.endDate = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Sản phẩm"
              label-for="product"
            >
              <ckeditor
                ref="ckRef"
                v-model="targetResearch.product"
                :config="editorConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('researchSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BButton, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'ResearchStudentSave',
  components: {
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    research: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetResearch: {
        organizationId: null,
        name: '',
        code: '',
        startDate: null,
        endDate: null,
        product: '',
        status: 1,
      },
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: true,
      },
      editorConfig: {},
      required,
    }
  },
  computed: {
    isCreated() {
      return !this.research
    },
  },
  methods: {
    ...mapActions({
      createResearch: 'researchStudent/createResearchStudent',
      updateResearch: 'researchStudent/updateResearchStudent',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.research) {
        this.targetResearch = { ...this.research }
      } else {
        this.targetResearch = { ...this.targetResearch, organizationId: this.user.orgId }
      }
    },
    onHide() {
      this.$refs
        .researchSaveFormRef
        .reset()
      this.targetResearch = {
        organizationId: null,
        name: '',
        code: '',
        startDate: null,
        endDate: null,
        product: '',
        status: 1,
      }
      if (this.$refs.ckRef.instance) this.$refs.ckRef.instance.destroy()
    },
    async onSave(type = null) {
      const valid = this.$refs
        .researchSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createResearch(this.targetResearch)
            : await this.updateResearch(this.targetResearch)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('researchSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-wrapper {
  width: 88%;
}
</style>
